import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import { DISABLED_TOOLTIP } from 'src/shared/util/events';
import { colors } from 'src/util/colors';

const EditHeader = ({ classes, onSave, onCancel, formId, savingDisabled }) => (
  <div className={classes.topButtons}>
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      className={classes.button}
      onClick={onCancel}
      tabIndex="-1"
    >
      Cancel
      <CancelIcon className={classes.rightIcon} />
    </Button>
    <Button
      variant="contained"
      color="primary"
      size="small"
      className={classes.button}
      onClick={onSave}
      form={formId}
      type="submit"
      disabled={savingDisabled}
    >
      Save
      <SaveIcon className={classes.rightIcon} />
    </Button>
  </div>
);

const ViewHeader = ({
  classes,
  onEdit,
  disableEdit,
  disableTooltip = DISABLED_TOOLTIP,
  showEditIcon,
}) =>
  !showEditIcon && (
    <Tooltip title={disableEdit ? disableTooltip : ''}>
      <span>
        <IconButton
          disabled={disableEdit}
          className={[classes.clickableIcon, classes.topButtons].join(' ')}
          onClick={onEdit}
          data-testid="edit-icon"
        >
          <EditIcon />
        </IconButton>
      </span>
    </Tooltip>
  );

const Header = ({ classes, isEditing, title, ...props }) => {
  let HeaderButtonComponent = null;
  if (isEditing) {
    HeaderButtonComponent = EditHeader;
  } else {
    HeaderButtonComponent = ViewHeader;
  }
  return (
    <div className={classes.header}>
      <Typography className={classes.heading} variant="h6">
        {title}
      </Typography>
      {HeaderButtonComponent && <HeaderButtonComponent classes={classes} {...props} />}
    </div>
  );
};

const styles = theme => ({
  clickableIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 18,
    '&:hover': {
      cursor: 'pointer',
    },
    padding: 5,
  },
  topButtons: {
    paddingBottom: 5,
  },
  heading: {
    color: colors.taupe,
  },
  header: {
    alignItems: 'flex-end',
    fontSize: 20,
    height: 40,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: colors.taupe,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    paddingBottom: 5,
    '&:not(:first-child)': {
      marginLeft: 5,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  '@media print': {
    topButtons: {
      display: 'none',
    },
  },
});

export default withStyles(styles)(Header);
