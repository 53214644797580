import React from 'react';

import TitleBar from 'src/components/pages/pageElements/titleBar';
import PatientPebblesTable from 'src/pebbles/components/PatientPebblesTable';

interface PatientPebblesProps {
  patientId: string;
}

const PatientPebbles: React.FC<PatientPebblesProps> = ({ patientId }) => {
  return (
    <div data-testid="pebbles-table">
      <TitleBar title="Pebbles" />
      <PatientPebblesTable patientId={patientId} />
    </div>
  );
};

export default PatientPebbles;
