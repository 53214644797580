import React from 'react';

import PebbleDetailsEdit from 'src/pebbles/components/PebbleDetailsEdit';
import PebbleDetailsView from 'src/pebbles/components/PebbleDetailsView';
import { Pebble } from 'src/pebbles/types';

type PebbleFormData = Pick<Pebble, 'id' | 'status' | 'assignee'>;
type PebbleDetailsFormProps = {
  formId: string;
  isEditing: boolean;
  isEditingPebbleDetails: boolean;
  onCancel: () => void;
  onEdit: () => void;
  onSave: (values: PebbleFormData) => Promise<void>;
  pebble: Pebble;
  setEditing: (name: string, value: boolean) => void;
  editingSectionName: string;
  canEditInteraction: boolean;
};

const PebbleDetailsForm: React.FC<PebbleDetailsFormProps> = ({ isEditing, ...props }) =>
  isEditing ? <PebbleDetailsEdit {...props} /> : <PebbleDetailsView {...props} />;

export default PebbleDetailsForm;
